import axios from '../utils/axios';

export function addSample(forms) {
    const data = {
        'name': forms.name,
        'mode': forms.mode,
        'count': forms.count,
        'orign': forms.orign,
        'address': forms.address,
        'receiver': forms.receiver,
        'create_time': forms.create_time
    }
    return axios.post('/sample/add', data)
}


export function listSample(current, size, queryItems) {
    const data = {
        current, size, ...queryItems
    }
    return axios.post('/sample/list', data)
}